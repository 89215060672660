export interface IRoundDates {
    round_type: string;
    round_date_status: string;
    round_date: (Date | null)[];
}

export const RoundTypes = [
    // { label: 'Group Discussion', value: 'group_discussion' },
    // { label: 'Machine Test', value: 'machine_test' },
    { label: 'Technical Interview', value: 'technical_interview' },
    { label: 'HR Interview', value: 'hr_interview' },
    // { label: 'Pre-Placement Talk', value: 'pre_placement_talk' }
];

export const RoundOptions = [
    { label: 'All', value: 'all', isActive: true },
    // { label: 'Group Discussion', value: 'group_discussion', isActive: false },
    // { label: 'Machine Test', value: 'machine_test', isActive: false },
    { label: 'Technical Interview', value: 'technical_interview', isActive: false },
    { label: 'HR Interview', value: 'hr_interview', isActive: false },
    { label: 'Event', value: 'event', isActive: false }
    // { label: 'Pre-Placement Talk', value: 'pre_placement_talk', isActive: false }
]

export const roundColors: { [key: string]: string } = {
    // "group_discussion": "rgb(255,173,58)",
    // "machine_test": "rgb(160,173,58)",
    "technical_interview": "rgb(3, 155, 229)",
    "hr_interview": "rgb(121, 134, 203)",
    // "pre_placement_talk": "rgb(255,153,158)",
    // "event": "#9C4C67"
}
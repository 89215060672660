import { HttpService } from '@services';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    constructor(
        private httpService: HttpService
    ) { }

    async fetchAccountMetaData(data: any) {
        try {
            const { group_account_id } = data;
            const res = await this.httpService.get(`/settings/getAccountMetaData/${group_account_id}`);
            return res;
        } catch (error) {
            throw new Error('Error in fetchAccountMetaData')
        }
    }

    async updateAccountMetaData(payload: any) {
        try {
            const res = await this.httpService.post('/settings/updateAccountMetadata', payload)
            return res
        } catch (error) {
            throw new Error('Error in updateAccountMetaData')
        }
    }

    async saveFields(payload: any) {
        try {
            const res = await this.httpService.post('/settings/saveFields', payload)
            return res
        } catch (error) {
            throw new Error('Error in saveFields')
        }
    }

    async allMailFromAddresses(payload: any) {
        return await this.httpService.post('/allMailFromAddresses', payload)
    }

    async getCollegeProfile(group_account_id: string) {
        return await this.httpService.get(`/collegeProfile/${group_account_id}`);
    }

    async updateCollegeProfile(payload: any) {
        return await this.httpService.post(`/updateCollegeProfile`, payload)
    }

    async updateCampusDetails(payload: any) {
        return await this.httpService.post(`/updateCampusDetails`, payload)
    }

    async fetchChoices(attribute_id: string) {
        const fetchChoices =
            await this.httpService.get(
                `/studentFields/fetchChoices/${attribute_id}`,
            );
        return fetchChoices;
    }

    async countStudentsWithAttribute(payload: any) {
        const fetchCount =
            await this.httpService.post(
                `/studentFields/countStudentsWithAttribute`,
                payload
            );
        return fetchCount;
    }


    async saveFieldMappings(body: any) {
        const addCategory =
            await this.httpService.post(
                '/studentFields/saveFieldMappings',
                body
            );
        return addCategory;
    }

    async fetchAllTemplate(body: any) {
        const addCategory =
            await this.httpService.post(
                '/emailTemplate/getAccountTemplate',
                body
            );
        return addCategory;
    }

    async fetchCategoryTemplate(body: any) {
        const addCategory =
            await this.httpService.post(
                '/emailTemplate/getCategoryTemplate',
                body
            );
        return addCategory;
    }

    async createTemplate(body: any) {
        const addCategory =
            await this.httpService.post(
                '/emailTemplate/addTemplate',
                body
            );
        return addCategory;
    }

    async updateTemplate(body: any) {
        const addCategory =
            await this.httpService.post(
                '/emailTemplate/updateTemplate',
                body
            );
        return addCategory;
    }

    async templateDelete(body: any) {
        const addCategory =
            await this.httpService.post(
                '/emailTemplate/deleteTemplate',
                body
            );
        return addCategory;
    }

    async updateAccountSettings(body: any) {
        const addCategory =
            await this.httpService.post(
                '/settings/updateAccountSettings',
                body
            );
        return addCategory;
    }

    async passCheck(body: any) {
        const addCategory =
            await this.httpService.post(
                '/passCheck',
                body
            );
        return addCategory;
    }

}

<div *ngIf="isLoading" class="{{(isLoading) ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div class="drive-round-creation-container" *ngIf="selectedRound">
    <div class="round-listing">
        <div class="each-round" (mouseenter)="onMouseEnter(round.event_id)" (mouseleave)="onMouseLeave(round.event_id)" (click)="onSelectRound(round)" *ngFor="let round of roundData" [ngClass]="{ 'selected-round': selectedRound?.order_id == round.order_id  }">
            <div class="round-header">
                <div class="round-name">Round {{ round.order_id }} Selected({{ studentCountOnEvent[round.event_id] || 0 }})</div>
                <div class="round-icons">
                    <ng-container *ngIf="hoveredEvent === round.event_id"> 
                        <span class="overlay-panel-close" (click)="deleteRound(round, $event)">
                            <img [src]="trashIcon" alt="trash" class="trash-icon"  />
                        </span>
                        <span class="overlay-panel-close" (click)="editRound(round, $event)" >
                            <img [src]="editIcon" alt="edit" class="edit-icon"/>
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="round-description">
                <span>{{ roundTypesLabel[round.round_type] }} | {{ dateStatusLabel[round.date_status] }}</span>
                <span>{{ round.start_date | date : globalService.defaultDateFormat }} - {{ round.end_date | date : globalService.defaultDateFormat }}</span>
            </div>
        </div>

        <div class="create-round-btn-container" *ngIf="roundData.length < 5">
            <app-button [btnProperties]="btnProperties.createRound" (buttonClick)="openRoundCreateSidebar()"></app-button>
        </div>
    </div>
    <div class="student-listing">
        <div>
            <div class="table-filters-drives-round-creation">
                <div class="filter-cont">
                    <span class="middle-left card flex justify-content-center p-float-label"
                        *ngFor="let row of filterData; let i = index">
                        <p-multiSelect [options]="row.array" [(ngModel)]="selectedFilters[row.key]" optionLabel="label"
                            optionValue="value"
                            [selectedItemsLabel]="'{selectedFilters[row.filterName.toLowerCase()].length} ' + 'item selected'"
                            [placeholder]="row.filterName" (onChange)="handleFilterChange(row.key, $event)">
                            <ng-template let-option pTemplate="item">
                                <div [pTooltip]="option.label.length > 30 ? option.label : null" tooltipPosition="top">
                                    {{ option.label.length > 40 ? option.label.substring(0, 40) + '...' : option.label }}
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <label style="font-size: var(--fs-medium);" for="campus">{{row.filterName}}</label>
                    </span>
        
                    <div class="clear-container">
                        <span class="flex gap-5 align-center">
                            <div class="add-delete-students">
                                <span class="clear-all" (click)="clearAllFilters()">Clear All</span>
                            </div>
                        </span>
        
                        <span class="flex gap-5 align-center">
                            <div class="add-delete-students">
                                <span class="apply-filters" (click)="applyFilter()">Apply Filter</span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="table-cont table-relative">
                <app-table class="rounds-student-table"
                    [loadingSpinner]="tableLoading" [columns]="columns" [dataList]="dataList" [dataCount]="dataCount"
                    [pageLimits]="pageLimits" (tableOptionsEvent)="tableOptionsEvent($event)" [captionsList]="captionsList"
                    [tableheight]="tableHeight" [totalPage]="totalPage" (searchChange)="searchChange($event)"
                    (search)="searchValueChange($event)" (rowLength)="rowLengthChange($event)" [hideTemplate]="true" 
                    (pageNo)="pageChange($event)" (checkboxStateChange)="handleCheckboxStateChange($event)" [isShowSelectedAll]="true"
                    [showDownload]="true" [searchBy]="search" (sortBy)="sortChange($event)"
                    (downloadEvent)="openDownloadDataSideBar()" [manually_actions]="true">
                </app-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!selectedRound && !isLoading" class="no-round-found-message"> 
    <div class="no-round-selected">
        <span>No Rounds Found</span>
    </div>
    <div class="create-round-btn-container" *ngIf="roundData.length < 5">
        <app-button [btnProperties]="btnProperties.createRound" (buttonClick)="openRoundCreateSidebar()"></app-button>
    </div>
</div>

<!-- create rounds -->
<p-sidebar class="round-creation-sidebar" [(visible)]="showRoundCreationSidebar" position="right" [dismissible]="false"
    (onHide)="onCloseRoundCreation()" width="500px">

    <ng-template pTemplate="header">
        <div class="round-creation-header">
            <span class="round-creation-text">{{ formType }} Round</span>
            <div class="header-btn">
                <app-button [btnProperties]="btnProperties['saveEligibility']"
                    (buttonClick)="addRound()"></app-button>
            </div>
        </div>

        <p-divider layout="horizontal" class="p-divider-width"></p-divider>
    </ng-template>

    <ng-template pTemplate="body">
        <div class="round-creation-body">
            <form class="overlay-panel-form" [formGroup]="roundCreationForm">
            
                <span class="p-float-label roundForm-elements">
                    <p-calendar formControlName="start_date" [readonlyInput]="true" dateFormat="dd-mm-yy" dataType="date" id="inputtext" (onSelect)="handleStartEndDateChange()"
                        [minDate]="currentDate" class="calendar-rounds" [showTime]="false" [hourFormat]="'12'" [showIcon]="true">
                    </p-calendar>
                    <label for="inputtext">Round Start Date<span class="imp">*</span></label>
                </span>
                
                <span class="p-float-label roundForm-elements">
                    <p-calendar formControlName="end_date" [readonlyInput]="true" dateFormat="dd-mm-yy" dataType="date" id="inputtext"
                        [minDate]="roundCreationForm.get('start_date')?.value" class="calendar-rounds" [showTime]="false" [hourFormat]="'12'" [showIcon]="true">
                    </p-calendar>
                    <label for="inputtext">Round End Date<span class="imp">*</span></label>
                </span>
    
                <span class="p-float-label roundForm-elements">
                    <p-dropdown class="dropdown-rounds" inputId="dropdown-dropdown" optionLabel="label" optionValue="value"
                        [options]="roundTypes" [autoDisplayFirst]="true" formControlName="round_type" />
                    <label for="inputtext">Round Type<span class="imp">*</span></label>
                </span>

                <span class="p-float-label roundForm-elements">
                    <p-dropdown class="dropdown-rounds" inputId="dropdown-dropdown" optionLabel="label" optionValue="value"
                        [options]="dateStatus" [autoDisplayFirst]="true" formControlName="date_status" />
                    <label for="inputtext">Date Status<span class="imp">*</span></label>
                </span>
            </form>
        </div>
    </ng-template>

</p-sidebar>

<!-- Add / Remove Students to rounds -->
<p-sidebar class="add-students-round" [(visible)]="showStudentActionSidebar" position="right" (onHide)="clearInputs()"
    [dismissible]="false">

    <ng-template pTemplate="header">
        <div class="round-creation-header">
            <span class="round-creation-text">{{sidebarPurpose === 'addStudents' ? 'Add Students To Round' : 'Remove Students From Round'}}</span>
            <div class="header-btn">
                <button pbutton class="p-button-create blue-filled-btn" (click)="roundAction()"
                    type="submit">{{sidebarPurpose === 'addStudents' ? 'Add Students' : 'Remove Students'}}
                </button>
            </div>
        </div>
    
        <p-divider layout="horizontal" class="p-divider-width"></p-divider>
    </ng-template>

    <ng-template pTemplate="body">
        <div class="sidebar-body">
            <div class="title-box manually-added" *ngIf="sidebarPurpose === 'addStudents'">
                <span>
                    Only students who are eligible for the drive will be added to the rounds.
                </span>
            </div>
            <div class="add-using-students">
                <span>Add Using<span class="imp">*</span></span>
                <div class="add-using">
                    <div *ngFor="let category of categories" class="field-checkbox">
                        <p-radioButton [value]="category" [(ngModel)]="selectedCategory"
                            (click)="categoryChange()"></p-radioButton>
                        <label [for]="category" class="ml-2">{{ category | fieldLabelPipe}}</label>
                    </div>
                </div>
                <div class="card flex justify-content-center p-float-label">
                    <p-chips [(ngModel)]="emailOrReg" separator="\n" (paste)="handlePaste($event)"
                        (onRemove)="errorData = []; errorDataToDisplay = []" (keydown)="handleKeydown($event)"
                        placeholder="Type {{fieldMaps[selectedCategory]}} and press enter to add">
                    </p-chips>
                    <label for="{{selectedCategory}}">{{selectedCategory | fieldLabelPipe}}
                        <span class="imp">*</span>
                    </label>
                </div>
            </div>
            <div class="error-message-preview" #tableContainer>
                <div class="table-container" *ngIf="errorDataToDisplay.length">
                        <p-table styleClass="p-datatable-gridlines" [columns]="tableColumns" [value]="errorDataToDisplay">
                            <ng-template pTemplate="caption">
                                <div class="caption-header">
                                    <div>
                                        <span class="caption-header-text">Error Student Data</span>
                                    </div>
                                    <div class="pagination-container">
                                        <div class="totallist-text">Total List : {{errorData.length}}</div>
                                        <p-divider layout="vertical"></p-divider>
                                        <div><span class="pi pi-download arrow-button" (click)="downloadErrorData()"></span></div>
                                        <p-divider layout="vertical"></p-divider>
                                        <div class="pagination-items">
                                            <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                                                {{ currentPageErrorTable }} to {{ totalPageErrorTable }}
                                            </button>
                                            <div class="page-front-back">
                                                <span><i class="pi pi-angle-left arrow-button" (click)="previousPagination()"></i></span>
                                                <span><i class="pi pi-angle-right arrow-button" (click)="nextPagination()"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" scope="col">
                                        {{ col.header }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <ng-container *ngFor="let col of columns">
                                        <td class="truncate-cell" [style.width]="col.width" pFrozenColumn>
                                            <span [pTooltip]="rowData[col.field]" [tooltipPosition]="'top'">
                                                {{ rowData[col.field] ? rowData[col.field] : "-" }}
                                            </span>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                        </p-table>
                </div>
            </div>
        </div>
    </ng-template>
</p-sidebar>


<!-- Download Data -->
<p-sidebar class="download-students" [(visible)]="showDownData" position="right" [dismissible]="false"
    (onHide)="hideDownloadBar()">

    <ng-template pTemplate="header">
        <div class="move-students-header">
            <span class="move-students-text">Download</span>
            <div class="header-btn">
                <app-button [btnProperties]="SBtnProperties['downloadStudent']"
                    (buttonClick)="downloadData()"></app-button>
            </div>
        </div>

        <p-divider layout="horizontal" class="p-divider-width"></p-divider>
    </ng-template>


    <ng-template pTemplate="body">
        <div class="sidebar-body">
            <div class="radioButton-group">
                <span>Download as</span>
                <div class="radioButton">
                    <p-radioButton value="excel" inputId="excel" [(ngModel)]="downloadColumnAs">
                    </p-radioButton>
                    <label for="excel"
                        [style]="{color: downloadColumnAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton value="csv" inputId="csv" [(ngModel)]="downloadColumnAs">
                    </p-radioButton>
                    <label for="csv"
                        [style]="{color: downloadColumnAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>
        </div>

        <div *ngFor="let categories of downloadCategories; let i = index" class="fields-container">
            <div class="title-box">
                <span>{{categories.header}}</span>
            </div>

            <div *ngIf="categories.data.length && !isLoading">
                <div class="selectAll-checkbox field-checkbox" *ngIf="categories.type !== 'resume'">
                    <p-checkbox [binary]="true" (onChange)="selectAllDownloadData(i)"
                        [(ngModel)]="categories.isSelectedAll"></p-checkbox>
                    <span>Select All</span>
                </div>

                <div class="fields">
                    <div *ngFor="let field of categories.data" class="field-checkbox">
                        <p-checkbox [binary]="true" [(ngModel)]="field.active"
                            (onChange)="onDropDownChange(i)"></p-checkbox>
                        <label [for]="field.label">{{field.label}}</label>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading; else noDataTemplate">
                <div class="no-data">
                    <span>Loading...</span>
                </div>
            </div>

            <ng-template #noDataTemplate>
                <div *ngIf="!categories || !categories.data || !categories.data.length" class="no-data">
                    <span>No Fields available</span>
                </div>
            </ng-template>
        </div>
    </ng-template>
</p-sidebar>

<p-toast></p-toast>
<p-confirmDialog [style]="{width: '35vw'}"></p-confirmDialog>
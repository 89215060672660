import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private httpService: HttpService) {}

  async fetchChats(payload: any) {
    return await this.httpService.post('/chat/fetchChats', payload);
  }

  async fetchBroadcasts(payload: any) {
    return await this.httpService.post('/chat/fetchBroadcasts', payload);
  }

  async fetchBroadcastInfo(payload: any) {
    return await this.httpService.post('/chat/fetchBroadcastInfo', payload);
  }

  async fetchTemplates() {
    return await this.httpService.get('/chat/getTemplates');
  }

  async sendTemplateMessage(payload: any) {
    return await this.httpService.post('/chat/sendTemplateMessage', payload);
  }

  async getDrives(payload: any) {
    let result = await this.httpService.post(`/drives/getDrives`, payload);
    return result.data;
  }

  async createBroadcasts(payload: any) {
    return await this.httpService.post('/chat/createBroadcasts', payload);
  }
}
